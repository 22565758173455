import React from 'react';

class Answer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFull: props.answer && props.answer.length < 300,
        };
    }

    showFull = (e) => {
        e.preventDefault();
        this.setState({
            showFull: true
        });
        return false;
    }

    render() {
        const { answer } = this.props;
        const { showFull } = this.state;

        if (!answer) {
            return null;
        }
        
        let text = answer;
        if (!showFull) {
            text = answer.substr(0, 300);
            text = text.substr(0, text.lastIndexOf(' '));
            text += '... '
        }
        console.log(this.state);

        return (
            <div className="echalupy-hodnoceni-list-item-answer">
                <div className="echalupy-hodnoceni-list-item-answer-title">Odpověď majitele:</div>
                {text}
                {!showFull && <button onClick={this.showFull} className="echalupy-hodnoceni-list-item-answer-show-full">Zobrazit celou odpověď</button>}
            </div>
        );
    }
}

export default Answer;