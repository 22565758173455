import request from 'superagent';

class ApiClient {
    url = "https://api2.e-chalupy.cz/konfigurator/hodnoceni/api/";

    getReviews = (objectId, callback) => {
        request
        .get(this.url)
        .query({ action: 'reviews', objectId: objectId }) // query string
        .end(callback);
    }
}

export default new ApiClient();
