import React, { Component } from 'react';
import apiClient from './apiClient';
import './App.css';
import List from './List/List';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reviews: [],
      objectUrl: null,
      ratings: null,
      loading: true,
    }
  }

  componentDidMount() {
    apiClient.getReviews(window.echalupyIdObjektu, (err, res) => {
      if(!err && res.body.items) {
        this.setState({ 
          reviews: res.body.items,
          objectUrl: res.body.objectUrl,
          ratings: res.body.ratings,
          loading: false
        });
      }
    });
  }

  render() {
    const { reviews, ratings, loading, objectUrl } = this.state;
    let classname = 'echalupy-hodnoceni-container';
    if (window.echalupyVzhled) {
      classname += ' echalupy-hodnoceni-theme-' + window.echalupyVzhled;
    }

    let content = !loading && <List reviews={reviews} objectId={window.echalupyIdObjektu} ratings={ratings} />;

    if (!loading && !objectUrl) {
      content = "Číslo objektu nenalezeno. Zkontrolujte zadané číslo objektu.";
    }

    return (
      <div className={classname}>
        {content}
      </div>
    );
  }
}

export default App;
