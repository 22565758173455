import React from 'react';
import star from '../images/star-on.png';
import './List.css';
import Answer from './Answer';

class List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showTotalRatings: window.celkoveHodnoceni === false || window.celkoveHodnoceni === "0" ? false : true
        };
        if(window.pocetZaznamu === "vse") {
            this.state.showItems = "vse";
        } else {
            this.state.showItems = parseInt(window.pocetZaznamu) > 0 ? parseInt(window.pocetZaznamu) : 10;
        }
    }

    showAll = (e) => {
        e.preventDefault();
        this.setState({
            showItems: "vse"
        });
        return false;
    }

    render() {
        const { reviews, objectId, ratings } = this.props;
        const { showItems, showTotalRatings } = this.state;

        return (
            <div className="echalupy-hodnoceni-list">
                {showTotalRatings &&
                    <div className="echalupy-hodnoceni-celkove">
                        <div className="echalupy-hodnoceni-celkove-title">Průměrné hodnocení {ratings.prumer}%</div>
                        <table className="echalupy-hodnoceni-celkove-table">
                            <tbody>
                                <tr>
                                    <td className="echalupy-hodnoceni-celkove-table-label">Komunikace s majitelem:</td>
                                    <td>
                                        <span className="echalupy-hodnoceni-celkove-table-rating">
                                            <span className="echalupy-hodnoceni-celkove-table-rating-value" style={{ width: ratings.komunikace + "%" }}></span>
                                        </span>
                                        {ratings.komunikace}%
                                    </td>
                                </tr>
                                <tr>
                                    <td className="echalupy-hodnoceni-celkove-table-label">Poloha objektu:</td>
                                    <td>
                                        <span className="echalupy-hodnoceni-celkove-table-rating">
                                            <span className="echalupy-hodnoceni-celkove-table-rating-value" style={{ width: ratings.poloha + "%" }}></span>
                                        </span>
                                        {ratings.poloha}%
                                    </td>
                                </tr>
                                {/*
                                <tr>
                                    <td className="echalupy-hodnoceni-celkove-table-label">Stav objektu:</td>
                                    <td>
                                        <span className="echalupy-hodnoceni-celkove-table-rating">
                                            <span className="echalupy-hodnoceni-celkove-table-rating-value" style={{ width: ratings.stav + "%" }}></span>
                                        </span>
                                        {ratings.stav}%
                                    </td>
                                </tr>
                                */}
                                <tr>
                                    <td className="echalupy-hodnoceni-celkove-table-label">Vybavení objektu:</td>
                                    <td>
                                        <span className="echalupy-hodnoceni-celkove-table-rating">
                                            <span className="echalupy-hodnoceni-celkove-table-rating-value" style={{ width: ratings.vybaveni + "%" }}></span>
                                        </span>
                                        {ratings.vybaveni}%
                                    </td>
                                </tr>
                                <tr>
                                    <td className="echalupy-hodnoceni-celkove-table-label">Čistota:</td>
                                    <td>
                                        <span className="echalupy-hodnoceni-celkove-table-rating">
                                            <span className="echalupy-hodnoceni-celkove-table-rating-value" style={{ width: ratings.cistota + "%" }}></span>
                                        </span>
                                        {ratings.cistota}%
                                    </td>
                                </tr>
                                {/*
                                <tr>
                                    <td className="echalupy-hodnoceni-celkove-table-label">Dostupnost výletů v okolí:</td>
                                    <td>
                                        <span className="echalupy-hodnoceni-celkove-table-rating">
                                            <span className="echalupy-hodnoceni-celkove-table-rating-value" style={{ width: ratings.vylety + "%" }}></span>
                                        </span>
                                        {ratings.vylety}%
                                    </td>
                                </tr>
                                */}
                                <tr>
                                    <td className="echalupy-hodnoceni-celkove-table-label">Celkový dojem:</td>
                                    <td>
                                        <span className="echalupy-hodnoceni-celkove-table-rating">
                                            <span className="echalupy-hodnoceni-celkove-table-rating-value" style={{ width: ratings.celkovy + "%" }}></span>
                                        </span>
                                        {ratings.celkovy}%
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
                <div>
                {reviews.map((review, index) => {
                    if(showItems !== "vse" && index >= showItems) {
                        return null;
                    }
                    return (
                        <div className="echalupy-hodnoceni-list-item" key={review.id}>
                            <div className="echalupy-hodnoceni-list-item-title">
                                <div className={`echalupy-hodnoceni-list-item-title-season echalupy-hodnoceni-list-item-title-season-${review.obdobi_id}`}>{review.obdobi} {review.rok}</div>
                                {" "}
                                <div className="echalupy-hodnoceni-list-item-title-author">
                                    {review.vlozil}
                                    {" "}
                                    {review.skupina !== "" &&
                                        <div className="echalupy-hodnoceni-list-item-title-group">{review.skupina}</div>
                                    }
                                </div>
                            </div>
                            <div className="echalupy-hodnoceni-list-item-rating">
                                Celkové hodnocení: <img src={star} alt="Počet hvězdiček" className="echalupy-hodnoceni-list-item-rating-icon" /> {Math.round(review.prumer*10)}%
                            </div>
                            {review.plusy !== "" &&
                                <div className="echalupy-hodnoceni-list-item-plus">
                                    <div className="echalupy-hodnoceni-list-item-plus-minus-title">Plusy:</div>
                                    {" "}
                                    {review.plusy}
                                </div>
                            }
                            {review.minusy !== "" &&
                                <div className="echalupy-hodnoceni-list-item-minus">
                                    <div className="echalupy-hodnoceni-list-item-plus-minus-title">Mínusy:</div>
                                    {" "}
                                    {review.minusy}
                                </div>
                            }
                            <Answer answer={review.reakce} />
                        </div>
                    );
                })}
                </div>
                {showItems !== "vse" && reviews.length > showItems &&
                    <a href="#" className="echalupy-hodnoceni-btn" target="_blank" onClick={this.showAll}>všechny recenze ({reviews.length})</a>
                }
                <a href={`https://www.e-chalupy.cz/nove-hodnoceni-${objectId}/`} className="echalupy-hodnoceni-btn" target="_blank">přidat hodnocení</a>
            </div>
        );
    }
}

export default List;